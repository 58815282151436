
export class AuthData {
    constructor(
        public provider: string,
        public accessToken?: string,
        public secret?: string,
        public idToken?: string,
        public authorizationCode?: string,
    ) {
    }
}